import { CameraAlt as ImageUploadIcon, Collections as MediaGallery, Info as InfoIcon, Download as DownloadIcon, FilePresent as FilePresentIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
  Typography,
  Box,
  IconButton
} from '@mui/material';
import { capitalCase } from 'change-case';
import { useState } from 'react';
import LinearProgressBar from 'src/components/progress-bar/progressBar.component';
import { CommonHelper } from 'src/utils/commonHelper';
import { integrateModuleURL, postsModuleURL } from 'src/services/urlService';
import HttpServices from 'src/services/httpService';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import CsvFileUploadComponent from './csv-file-upload.component';
import { useToaster } from 'src/utils/toaster/toasterContext';

dayjs.extend(utc);
dayjs.extend(timezone);

const PostBulkUploadComponent = ({ handleClose, handleUpload, fileUploadprogress, configuredSocialMedia }) => {
  const theme = useTheme();
  const { showToast } = useToaster();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mediaFiles, setMediaFiles] = useState([]);
  const [privateMediaFiles, setPrivateMediaFiles] = useState([]);
  const [isUploadLoading, setUploadLoading] = useState(false);
  const [bulckJsonData, setBulckJsonData] = useState([]);
  const [isUpload, setIsUplaod] = useState(false);
  const [isDownloadCsvLoading, setDownloadCsvLoading] = useState(false);
  const brandTimeZone = CommonHelper.GetBrandTimeZone();
  const filterConnectedNetworks = configuredSocialMedia?.filter((a) => a.is_enabled && a.is_active);
  const isPinterestProfileAvailable = filterConnectedNetworks.some(a => a.service_name === 'pinterest');

  const CurrentBrandTime = dayjs().tz(brandTimeZone).format('DD/MM/YYYY HH:mm:ss');

  const handleDownloadSampleCSV = () => {
    setDownloadCsvLoading(true);

    if (isPinterestProfileAvailable) {
      const pinterestNetworks = filterConnectedNetworks.filter(network => network.service_name === 'pinterest');
      const boardPromises = pinterestNetworks.map(network => fetchAllPinterestBoards(network.id));

      Promise.all(boardPromises)
        .then(boardResults => {
          const fetchAllBoardsById = pinterestNetworks.reduce((acc, network, index) => {
            acc[network.id] = boardResults[index];
            return acc;
          }, {});

          downloadSampleCSV(fetchAllBoardsById);
        })
        .catch(error => {
          showToast('Error fetching Pinterest boards');
          downloadSampleCSV();
        })
        .finally(() => setDownloadCsvLoading(false));
    } else {
      downloadSampleCSV();
    }
  };

  const downloadSampleCSV = (fetchAllBoardsById = {}) => {
    const csvRowsFirst = generateRows(1, fetchAllBoardsById);
    const csvRowsSecond = generateRows(2, fetchAllBoardsById);

    const combinedCsvRows = [...csvRowsFirst, ...csvRowsSecond];
    generateCsvFile(combinedCsvRows);
  }

  const fetchAllPinterestBoards = (networkId) => {
    return new Promise((resolve, reject) => {
      const getProps = {
        url: `${integrateModuleURL.FetchAllBoards}${networkId}`,
        successCallback: (data) => {
          if (data && Array.isArray(data) && data.length > 0) {
            const boardNames = data.map(board => board.name);
            resolve(boardNames);
          } else {
            resolve([]);
          }
        },
        failureCallback: (error) => {
          console.error(`Error fetching boards for network ID ${networkId}:`, error);
          reject(error);
        },
      };

      HttpServices.Get(getProps);
    });
  };

  const fetchSampleData = (network, fetchAllBoardsById) => {
    let firstComment = '';
    let postType = '';
    let youtubePrivacy = '';
    let youtubeTitle = '';
    let youtubeThumbnail = '';
    let pinterestTitle = '';
    let pinterestLink = '';
    let pinterestBoard = '';
    let pinterestCoverImage = '';
    let gbusinessButtonType = '';
    let gbusinessUrl = '';
    let gbusinessTitle = '';
    let gbusinessStartDate = '';
    let gbusinessEndDate = '';
    let gbusinessStartTime = '';
    let gbusinessEndTime = '';
    let gbusinessOfferCode = '';
    let gbusinessOfferLink = '';
    let gbusinessOfferTermsandConditions = '';
    let tiktokPrivacy = '';
    let tiktokComments = '';
    let tiktokDuet = '';
    let tiktokStitch = '';
    let tiktokYourBrand = '';
    let tiktokBrandedContent = '';
    const boardsForAccount = fetchAllBoardsById[network.id];

    switch (network.service_name) {
      case 'facebook':
        firstComment = 'Sample First Comment';
        postType = 'Post / Reels';
        break;
      case 'instagram':
        firstComment = 'Sample First Comment';
        postType = 'Post / Reels / Story';
        break;
      case 'twitter':
        postType = 'Post';
        break;
      case 'youtube':
        firstComment = 'Sample First Comment';
        postType = 'Video / Shorts';
        youtubePrivacy = 'Public / Private / Unlisted';
        youtubeTitle = 'Sample Youtube Title';
        youtubeThumbnail = '';
        break;
      case 'linkedin':
        firstComment = 'Sample First Comment';
        postType = 'Post';
        break;
      case 'gbusiness':
        postType = 'STANDARD / OFFER / EVENT';
        gbusinessButtonType = 'None / BOOK / ORDER / LEARN_MORE / SIGN_UP / CALL';
        gbusinessUrl = 'Sample Gbusiness Url';
        gbusinessTitle = 'Sample Gbusiness Title';
        gbusinessStartDate = CurrentBrandTime;
        gbusinessEndDate = CurrentBrandTime;
        gbusinessStartTime = CurrentBrandTime;
        gbusinessEndTime = CurrentBrandTime;
        gbusinessOfferCode = 'Sample Gbusiness Offer Code';
        gbusinessOfferLink = 'Add a URL link for the Gbusiness Offers';
        gbusinessOfferTermsandConditions = 'Sample Terms and Conditions';
        break;
      case 'tiktok':
        postType = 'UPLOAD_TO_INBOX / DIRECT_POST';
        tiktokPrivacy = 'PUBLIC_TO_EVERYONE / MUTUAL_FOLLOW_FRIENDS / FOLLOWER_OF_CREATOR / SELF_ONLY';
        tiktokComments = 'TRUE';
        tiktokDuet = 'TRUE';
        tiktokStitch = 'TRUE';
        tiktokYourBrand = 'TRUE';
        tiktokBrandedContent = 'TRUE';
        break;
      case 'linkedin':
        postType = 'Post';
        break;
      case 'pinterest':
        postType = 'Post';
        pinterestTitle = 'Sample Pinterest Title';
        if (boardsForAccount) {
          pinterestBoard = `${boardsForAccount.join(' / ')} (Please select at least one board)`;
        } else {
          pinterestBoard = 'No boards available';
        }
        pinterestLink = 'Sample Pinterest link';
        pinterestCoverImage = '';
        break;
      default:
        break;
    }
    return { firstComment, postType, youtubePrivacy, youtubeTitle, youtubeThumbnail, pinterestTitle, pinterestBoard, pinterestLink, pinterestCoverImage, gbusinessButtonType, gbusinessUrl, gbusinessTitle, gbusinessStartDate, gbusinessEndDate, gbusinessStartTime, gbusinessEndTime, gbusinessOfferCode, gbusinessOfferLink, gbusinessOfferTermsandConditions, tiktokPrivacy, tiktokComments, tiktokDuet, tiktokStitch, tiktokYourBrand, tiktokBrandedContent };
  }

  const generateRows = (scheduleNumber, fetchAllBoardsById) => {

    return filterConnectedNetworks.map((network, index) => {
      const {
        firstComment,
        postType,
        youtubePrivacy,
        youtubeTitle,
        youtubeThumbnail,
        pinterestTitle,
        pinterestBoard,
        pinterestLink,
        pinterestCoverImage,
        gbusinessButtonType,
        gbusinessUrl,
        gbusinessTitle,
        gbusinessStartDate,
        gbusinessEndDate,
        gbusinessStartTime,
        gbusinessEndTime,
        gbusinessOfferCode,
        gbusinessOfferLink,
        gbusinessOfferTermsandConditions,
        tiktokPrivacy,
        tiktokComments,
        tiktokDuet,
        tiktokStitch,
        tiktokYourBrand,
        tiktokBrandedContent,
      } = fetchSampleData(network, fetchAllBoardsById);

      return {
        "Schedule No": index === 0 ? scheduleNumber : "",
        "Schedule Date/Time": index === 0 ? CurrentBrandTime : "",
        "Common Description": index === 0 ? "Sample Common Description" : "",
        "Common First Comment": index === 0 ? "Sample Common First Comment" : "",
        "Is Draft": index === 0 ? "TRUE" : "",
        "Need Approval": index === 0 ? "FALSE" : "",
        "Errors": "",
        "Connected Profile Id": network.id,
        "Connected Profile Name": network.profile_name,
        "Social Media": network.service_name,
        "Description": "Sample Description",
        "First Comment": firstComment,
        "Post Type": postType,
        "Youtube Privacy": youtubePrivacy,
        "Youtube Title": youtubeTitle,
        "Youtube Thumbnail": youtubeThumbnail,
        "Pinterest Title": pinterestTitle,
        "Pinterest Boards": pinterestBoard,
        "Pinterest Link": pinterestLink,
        "Pinterest Cover Image": pinterestCoverImage,
        "Gbusiness Button Type": gbusinessButtonType,
        "Gbusiness Url": gbusinessUrl,
        "Gbusiness Title": gbusinessTitle,
        "Gbusiness Start Date": gbusinessStartDate,
        "Gbusiness End Date": gbusinessEndDate,
        "Gbusiness Start Time": gbusinessStartTime,
        "Gbusiness End Time": gbusinessEndTime,
        "Gbusiness Offer Code": gbusinessOfferCode,
        "Gbusiness Offer Link": gbusinessOfferLink,
        "Gbusiness Terms & Conditions": gbusinessOfferTermsandConditions,
        "TikTok Privacy": tiktokPrivacy,
        "TikTok Comments": tiktokComments,
        "TikTok Duet": tiktokDuet,
        "TikTok Stitch": tiktokStitch,
        "Tiktok Your Brand": tiktokYourBrand,
        "Tiktok Branded Content": tiktokBrandedContent,
        "Media Url 1": "https://sample-picture.png",
        "Media Url 2": "",
        "Media Url 3": "",
        "Media Url 4": "",
        "Media Url 5": "",
        "Media Url 6": "",
        "Media Url 7": "",
        "Media Url 8": "",
        "Media Url 9": "",
        "Media Url 10": "",
      };
    });
  };

  const generateCsvFile = (rows) => {

    const headers = [
      "Schedule No", "Schedule Date/Time", "Common Description", "Common First Comment", "Is Draft", "Need Approval", "Errors",
      "Connected Profile Id", "Connected Profile Name", "Social Media", "Description", "First Comment",
      "Post Type", "Youtube Privacy", "Youtube Title", "Youtube Thumbnail", "Pinterest Title", "Pinterest Boards",
      "Pinterest Link", "Pinterest Cover Image", "Gbusiness Button Type", "Gbusiness Url", "Gbusiness Title",
      "Gbusiness Start Date", "Gbusiness End Date", "Gbusiness Start Time", "Gbusiness End Time", "Gbusiness Offer Code",
      "Gbusiness Offer Link", "Gbusiness Terms & Conditions", "TikTok Privacy", "TikTok Comments", "TikTok Duet",
      "TikTok Stitch", "Tiktok Your Brand", "Tiktok Branded Content", "Media Url 1", "Media Url 2", "Media Url 3", "Media Url 4",
      "Media Url 5", "Media Url 6", "Media Url 7", "Media Url 8", "Media Url 9", "Media Url 10",
    ];

    const csvContent = [
      headers.join(","),
      ...rows.map(row =>
        [
          row["Schedule No"], row["Schedule Date/Time"], row["Common Description"], row["Common First Comment"], row["Is Draft"], row["Need Approval"], row["Errors"],
          row["Connected Profile Id"], row["Connected Profile Name"], row["Social Media"], row["Description"],
          row["First Comment"], row["Post Type"], row["Youtube Privacy"], row["Youtube Title"], row["Youtube Thumbnail"],
          row["Pinterest Title"], row["Pinterest Boards"], row["Pinterest Link"], row["Pinterest Cover Image"],
          row["Gbusiness Button Type"], row["Gbusiness Url"], row["Gbusiness Title"], row["Gbusiness Start Date"],
          row["Gbusiness End Date"], row["Gbusiness Start Time"], row["Gbusiness End Time"], row["Gbusiness Offer Code"],
          row["Gbusiness Offer Link"], row["Gbusiness Terms & Conditions"], row["TikTok Privacy"], row["TikTok Comments"],
          row["TikTok Duet"], row["TikTok Stitch"], row["Tiktok Your Brand"], row["Tiktok Branded Content"], row["Media Url 1"], row["Media Url 2"], row["Media Url 3"], row["Media Url 4"], row["Media Url 5"], row["Media Url 6"], row["Media Url 7"], row["Media Url 8"], row["Media Url 9"], row["Media Url 10"]
        ].join(",")
      ),
    ].join("\n");

    downloadCsv(csvContent);
  };

  const downloadCsv = (csvContent) => {
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const filename = "B2s_sampleTemplate.csv";
      link.setAttribute("href", URL.createObjectURL(blob));
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const hanldeUploadMediaFiles = () => {
    setUploadLoading(true);
    const blob = new Blob([JSON.stringify(bulckJsonData, null, 2)], { type: "application/json" });
    const formData = new FormData();
    formData.append("jsonFile", blob, "bulk-post.json");
    const postProps = {
      url: postsModuleURL.uploadBulkPosts,
      body: formData,
      successCallback: (data, message) => {
        showToast(message, 's')
        setUploadLoading(false);
        onClose()
      },
      failureCallback: (message) => {
        showToast(message, 'e')
        setUploadLoading(false);
      },
      contentType: "form"
    };
    HttpServices.Post(postProps);
  };

  const handleBulkJson = (data) => {
    setIsUplaod(true);
    setBulckJsonData(data);
  }

  const onClose = () => {
    handleClose();
  };
  return (
    <Dialog open onClose={handleClose} maxWidth="md" fullWidth PaperProps={{
      style: {
        minHeight: '300px',
        maxHeight: '90vh',
        height: '90vh'
      },
    }}>
      <DialogTitle>Bulk Upload</DialogTitle>
      <DialogContent>
        <Typography sx={{ mb: 2 }}>Upload posts using a CSV file. Just download the template, add your posts, and upload it.</Typography>
        <LoadingButton variant="outlined" startIcon={<DownloadIcon />} loading={isDownloadCsvLoading} onClick={handleDownloadSampleCSV} sx={{ mb: 2 }}>
          Download Sample CSV
        </LoadingButton>
        <CsvFileUploadComponent handleBulkJson={handleBulkJson} />
        {isUpload &&
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              p: 2,
              mt: '1rem',
              width: 100,
              height: 100,
              borderRadius: 2,
              overflow: 'hidden',
              position: 'relative',
              backgroundColor: theme.palette.background.default,
              border: `1px solid ${theme.palette.divider}`,
              transition: 'all 0.3s ease',
            }}
          >
            <IconButton
              sx={{
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
                width: 50,
                height: 50,
                mb: 1,
              }}
            >
              <FilePresentIcon sx={{ fontSize: 30 }} />
            </IconButton>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 500,
                textAlign: 'center',
                color: theme.palette.text.secondary,
              }}
            >
              CSV File
            </Typography>
          </Box>
        }
      </DialogContent>
      <DialogActions
        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <div style={{ flex: 1 }}>{fileUploadprogress > 0 && <LinearProgressBar value={fileUploadprogress} />}</div>
        <div style={{ display: 'flex', gap: '8px' }}>
          <Button variant="outlined" color="error" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton variant="contained" onClick={hanldeUploadMediaFiles} autoFocus loading={isUploadLoading} disabled={!isUpload}>
            Upload
          </LoadingButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};
export default PostBulkUploadComponent;
